const tours = [
  {
    tour_id: 1,
    name: 'tour_create_booking',
    description: 'tour_create_booking_description',
    path: '/?tours=1',
    icon: 'user-find.svg'
  },
  {
    tour_id: 2,
    name: 'tour_list_booking',
    description: 'tour_list_booking_description',
    path: '/customer/bookings',
    background: 'background-green',
    icon: 'calendar_month.svg'
  },
  {
    tour_id: 3,
    name: 'tour_create_translation',
    description: 'tour_create_translation_description',
    path: `/?draft=${btoa(JSON.stringify({ type: 'text_translation' }))}`,
    icon: 'translate.svg'
  },
  {
    tour_id: 4,
    name: null,
    description: null,
    path: `/today-dashboard`,
    icon: 'translate.svg'
  },
  {
    tour_id: 5,
    name: null,
    description: null,
    path: `/today-dashboard`,
    icon: 'translate.svg'
  },
  {
    tour_id: 6,
    name: null,
    description: null,
    path: `/today-dashboard`,
    icon: 'translate.svg'
  }
]

export default () => {
  return {
    tours
  }
}
