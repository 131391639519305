export default {
  getCustomerTours: (state, getters, rootState) => {
    let customerTours =
      rootState.auth?.userMeta?.data?.tours?.filter(
        (i) =>
          parseInt(i.pivot.is_assigned) && parseInt(i.pivot.times_completed_tour) < parseInt(i.pivot.display_duration)
      ) ?? []
    if (customerTours.length === 0) return []

    let filteredTours = state.tours.filter((tour) => customerTours?.map((i) => i.id)?.includes(tour.tour_id))

    return filteredTours.map((tour) => {
      return {
        ...tour,
        ...customerTours.find((i) => i.id === tour.tour_id)
      }
    })
  }
}
