import { driver } from 'driver.js'
import 'driver.js/dist/driver.css'

export default {
  install: (app) => {
    let defaultConfig = {
      showProgress: true,
      animate: true,
      opacity: 0.75,
      allowClose: false,
      padding: 10,
      doneBtnText: 'Done',
      closeBtnText: 'Close',
      nextBtnText: 'Next',
      showButtons: ['next', 'done']
    }
    let driverObj = driver(defaultConfig)
    app.config.globalProperties.$tour = {
      startTour: (steps, config = {}) => {
        driverObj.setConfig({ ...config, ...defaultConfig })
        driverObj.setSteps(steps)
        driverObj.drive()
      },
      highlightElement: (selector) => {
        driverObj.highlight(selector)
      }
    }
  }
}
