import * as API from '@/modules/constants/api.js'
import { reach } from '@/modules/helpers/vuexActions.js'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance.js'

export default {
  incrementTourCounter(context, payload = {}) {
    const url = `${API.BASE}/api/v3/core/tour/increment-tour-completion`

    let options = {
      data: payload,
      is_data_raw: true
    }
    return reach(this, context, url, 'POST', options, axiosInstance)
  }
}
