/*
|--------------------------------------------------------------------------
| Store > Booking > Actions > validateRecurringBookings
|--------------------------------------------------------------------------
|
*/
import { reach } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import API from '@/modules/constants/api'

/**
 * Action to validate the booking to make sure values are right,
 * and then return the additional values to be used for booking confirmation
 * as response.
 *
 * @param {object} context
 *   The scope of the Vuex store
 *
 * @return {*}
 */
export default function (context) {
  // Prepare the payload to be sent across the API.
  context.commit('setRecurringFormPayload', {
    user: context.rootGetters['auth/userMetaData']
  })

  context.commit('setValidateLoading', true)

  const url = `${API.RECURRING_BOOKINGS}/validate`
  const method = 'post'
  const data = context.state.formPayload

  const options = {
    enable_error_notif: true,
    data,

    on_success(r, ctx) {
      ctx.commit('resetConfirmFormPayload')

      // Set the following states.
      ctx.commit('setBcfConfig', r.data.booking_field)
      ctx.commit('setConfirmSuggestions', r.data.confirmation_fills)
      // translator shortages are not returned as part of recurring validation,
      // so settings defaults here to clear out any shortages set in another context.
      ctx.commit('setTranslatorShortages', {
        translator_shortages: {
          count: false,
          specific_translator: null,
          gender: null,
          translator_level: null,
          physical: null,
          country: null
        }
      })
      let user = ctx.rootGetters['auth/userMetaData']

      ctx.commit('setConfirmForm', {
        data: r.data.confirmation_fills,
        user
      })
    },

    on_finally(ctx) {
      ctx.commit('setValidateLoading', false)
    }
  }

  return reach(this, context, url, method, options, axiosInstance)
}
