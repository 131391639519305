/*
|--------------------------------------------------------------------------
| Store > Booking > Actions > loadTranslatorOptsGeneric
|--------------------------------------------------------------------------
|
*/

import API from '@/modules/constants/api'
import { reach } from '@/modules/helpers/vuexActions'
import isNil from 'lodash/isNil'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

/**
 * Action to load translator options.
 *
 * @param {Object} context - Current context of the Vuex module.
 * @param {Object} payload - Contains necessary values usable within the method.
 * @param {Object} payload.translator_name - Filter for getting translators with specific name(s).
 * @param {Object} payload.translator_id - Filter for getting translators with specific id(s).
 * @returns {Promise}
 */
export default function (context, payload = {}) {
  const url = API.TRANSLATORS
  const method = 'GET'

  let options = {
    query_params: {
      'all': true,
      'fields[users]': 'id,name',
      'sort': 'name'
    }
  }

  // Prevent request if no translator_id or translator_name
  if (
    (isNil(payload.translator_name) || payload.translator_name === '') &&
    (isNil(payload.translator_id) || payload.translator_id === '' || payload.translator_id === 0)
  ) {
    console.warn('prevent load all translator data.')
    return Promise.resolve(context.state.defaultResponse) // Return a resolved promise with no value
  }

  if (!isNil(payload.translator_name) && payload.translator_name !== '') {
    options.query_params['filter[name]'] = payload.translator_name
  }

  if (!isNil(payload.translator_id) && payload.translator_id !== '' && payload.translator_id !== 0) {
    options.query_params['filter[id]'] = payload.translator_id
  }

  return reach(this, context, url, method, options, axiosInstance)
}
