<template>
  <focus-trap :active="isShowModal" :initial-focus="() => $refs.init_focus">
    <el-dialog
      v-model="isShowModal"
      class="app-modal app-modal-secondary"
      :class="getBem(blockCls)"
      :title="$t('need_feedback')"
      :append-to-body="true"
      top="3%"
    >
      <a ref="init_focus" style="font-size: 1px" tabindex="-1" href="#">.</a>
      <el-row :gutter="20" :class="getBem(blockCls, 'modal-col')">
        <template v-if="!isNil(booking)">
          <el-col :md="6">
            {{ $t('booking_id') }}
          </el-col>
          <el-col :md="16">
            <h5 style="color: #818596">
              {{ feedback.booking_issue.booking.id }}
            </h5>
          </el-col>

          <el-col :md="6">
            {{ $t('booking_type') }}
          </el-col>
          <el-col :md="16">
            <h5 style="color: #818596">
              {{ $t(`booking_type_${feedback.booking_issue.booking.type}`) }}
            </h5>
          </el-col>

          <el-col :md="6">
            {{ $t('booking_due') }}
          </el-col>
          <el-col :md="16">
            <h5 style="color: #818596">
              {{ feedback.booking_issue.booking.due }}
            </h5>
          </el-col>

          <el-col :md="6">
            {{ $t('language') }}
          </el-col>
          <el-col :md="16">
            <h5 style="color: #818596">
              {{ languageOpts[feedback.booking_issue.booking.from_language_id] }}
            </h5>
          </el-col>

          <el-col :md="6">
            {{ $t('replay_deadline') }}
          </el-col>
          <el-col :md="16">
            <h5 style="color: #818596">
              {{ userFeedback.pivot.deadline_date }}
            </h5>
          </el-col>

          <el-col :md="6">
            {{ $t('deviation_description') }}
          </el-col>
          <el-col :md="16">
            <h5 style="color: #818596">
              {{ reasons }}
            </h5>
          </el-col>
        </template>
      </el-row>

      <el-row v-if="!isExpired" :gutter="20" :class="getBem(blockCls, 'modal-bottom-col')">
        <el-col :md="6">
          {{ $t('comment') }}
        </el-col>
        <el-col :md="16">
          <!--        {{// feedback.comment}}-->
          <div v-html="feedback.comment" />
        </el-col>

        <template v-if="showIsUploadOption">
          <el-col :md="6">
            {{ $t('upload') }}
          </el-col>
          <el-col :md="16">
            <aws-upload-field v-model="form.comment_files" />
          </el-col>
          <br />
        </template>

        <el-col :md="6">
          {{ $t('give_feedback') }}
        </el-col>
        <el-col :md="16">
          <el-input v-model="form.comment" type="textarea" :rows="5" />
          <el-button type="primary" tabindex="0" @click="handleClickSubmit">
            {{ $t('btn_submit') }}
          </el-button>
        </el-col>

        <el-col :offset="18" />
      </el-row>
    </el-dialog>
  </focus-trap>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { ISSUES } from '@/modules/constants/api'
import { showSmallSuccessNotif } from '@/modules/helpers/notifications'
import filter from 'lodash/filter'
import isNil from 'lodash/isNil'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import includes from 'lodash/includes'
import AwsUploadField from '@/modules/booking/components/forms/AwsUploadField'
import isNull from 'lodash/isNull'
import moment from 'moment-timezone'

export default {
  components: {
    AwsUploadField
  },

  props: {
    getMyIssues: {
      type: Function,
      default: () => null
    }
  },
  /*
      |--------------------------------------------------------------------------
      | Component > data
      |--------------------------------------------------------------------------
      */
  data() {
    return {
      isShowModal: false,
      blockCls: 'feedback-submit-modal',
      feedback: {},
      form: {
        comment_files: [],
        feedback_id: ''
      },
      fileList: []
    }
  },

  /*
      |--------------------------------------------------------------------------
      | Component > computed
      |--------------------------------------------------------------------------
      */
  computed: {
    ...mapGetters('auth', ['user']),
    ...mapGetters('booking', ['languageOpts']),
    /**
     * It will find out the feedback which is assigned to
     * the current user because there is other assigned user as well
     */
    userFeedback() {
      return this.feedback.assigned_users?.filter((item) => {
        return item.id == this.user.id
      })[0]
    },
    /**
     * Action type could be text,upload
     * Will use it later
     */
    actionType() {
      return this.userFeedback?.pivot.actions.split(',')
    },

    /**
     * Feedback Issue Id
     *
     */
    feedbackIssueId() {
      return this.feedback.booking_issue_id
    },

    showIsUploadOption() {
      if (!isNil(this.feedback) && !isNil(this.feedback.assigned_users)) {
        let user = this.feedback.assigned_users.filter((user) => {
          return user.id === this.user.id
        })
        if (includes(user[0].pivot.actions.toLowerCase(), 'upload')) {
          return true
        }
      }
      return false
    },

    booking() {
      return !isNil(this.feedback.booking_issue) && !isNil(this.feedback.booking_issue.booking)
        ? this.feedback.booking_issue.booking
        : null
    },
    isExpired() {
      if (!isNull(this.userFeedback?.pivot.deadline_date)) {
        return moment().isAfter(this.userFeedback?.pivot.deadline_date)
      }

      return false
    },

    notifyThrough() {
      if (!isNil(this.feedback) && !isNil(this.feedback.assigned_users)) {
        let user = this.feedback.assigned_users.filter((user) => {
          return user.id === this.user.id
        })

        return user[0].pivot.notify_through
      }

      return this.user.eamil
    },
    reasons() {
      const feedback = this.feedback.booking_issue.booking.feedback.find((o) => o.review_from_type == 2)
      return feedback?.reasons.map((reason) => reason.reason.replace(/\.$/, '')).join(', ')
    }
  },

  /*
      |--------------------------------------------------------------------------
      | Component > methods
      |--------------------------------------------------------------------------
      */
  methods: {
    ...mapActions('booking', ['loadLanguageOpts']),
    open(feedback) {
      this.feedback = feedback
      this.isShowModal = true
      this.showIsUploadOption
    },
    /**
     *
     */
    handleClickSubmit() {
      const feedback = this.feedback.booking_issue.booking.feedback.find((o) => o.review_from_type == 2)
      this.form.comment_id = this.feedback.id
      this.form.feedback_id = feedback.id
      this.form.translator_answer = this.form.comment
      axiosInstance({
        method: 'POST',
        url: ISSUES + '/' + this.feedbackIssueId + '/comment',
        data: this.form
      }).then(() => {
        // let ticketPayload = {
        //   requester: {
        //     name: this.user.name,
        //     email: this.notifyThrough
        //   },
        //   title: `RE: Viktigt: Vi behöver din återkoppling rörande en bokning (bokn.nr #${this.feedback.booking_issue.booking.id})`,
        //   body: this.form.comment,
        //   dt_user_id: this.user.id,
        //   booking_issue_id: this.feedbackIssueId,
        //   deadline_date: this.feedback.booking_issue.deadline_date,
        //   notify: false
        // }
        // this.$store.dispatch('email/createTicket', ticketPayload)

        showSmallSuccessNotif(this.$t('feedback_submitted'))

        this.getMyIssues()
      })
      this.isShowModal = false
    },

    /**
     *
     */
    handleRemove(e) {
      this.form.file = filter(this.form.file, (i) => i.uid !== e.uid)
    },

    /**
     *
     */
    handleFilesUpload() {
      this.form.comment_files = this.$refs.file.files[0]
    },

    isNil
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables/index.scss';
@import '@/assets/scss/global/index.scss';
.feedback-submit-modal {
  .el-col {
    margin-top: 5px;

    h5 {
      margin-top: 0px;
      margin-bottom: 0px;
      font-size: 15px;
    }

    .el-textarea__inner {
      min-height: 100px !important;
    }
  }

  &__modal-bottom-col {
    margin-top: 20px;

    .el-col {
      margin-top: 10px;
    }

    .el-button {
      float: right;
      margin-top: 5px;
    }
  }
}
</style>
