import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import { AUTH_USER } from '@/modules/constants/api'
import { CUSTOMER_AFTER_LOGIN, TRANSLATOR_AFTER_LOGIN, ADMIN_AFTER_LOGIN } from '@/modules/constants/redirectPaths'
import router from '@/router'
import { getLocaleRoutePath } from '@/modules/helpers/routes'
import { showSmallErrorNotif } from '@/modules/helpers/notifications'
import i18n from '@/locales/i18nClient.js'

const { t: $t } = i18n.global

/**
 * @param {object} context
 * @param {object} payload
 * @param {string} payload.token
 */
function loginAs(context, payload) {
  payload.token = payload.token || ''

  if (payload.token === '') {
    console.error('Invalid token')
    return
  }

  context.commit('auth/destroyToken', null, { root: true })
  // Place a bit of delay to properly set the token value and
  // make sure the removal of token is finished.
  setTimeout(() => {
    context.commit('auth/setToken', payload.token, { root: true })
    // axios.defaults.headers.common['Authorization'] = 'Bearer ' + payload.token

    getUser().then((user) => {
      context.commit('auth/setUser', user, { root: true })

      context.dispatch('auth/initToken', null, { root: true }).then(() => {
        let path

        let queryParams = {}
        if (context.rootGetters['auth/isUserCustomer']) {
          console.log('User is Customer, redirecting to proper page...')
          path = getLocaleRoutePath(CUSTOMER_AFTER_LOGIN, this)
        } else if (context.rootGetters['auth/isUserTranslator']) {
          console.log('User is Translator, redirecting to proper page...')
          let loginPath = payload?.redirect_url ?? TRANSLATOR_AFTER_LOGIN
          //extract query params from the url
          let uri = new URL(loginPath, window.location.origin)
          queryParams = Object.fromEntries(uri.searchParams.entries())
          path = getLocaleRoutePath(loginPath, this)
        } else if (context.rootGetters['auth/isUserAdmin']) {
          console.log('User is Admin, redirecting to proper page...')
          path = getLocaleRoutePath(ADMIN_AFTER_LOGIN, this)
        }
        router.push({ path, query: queryParams })
      })
    })
  }, 750)
}

/**
 * @returns {Promise<object>}
 */
function getUser() {
  return new Promise((resolve, reject) => {
    axiosInstance({ method: 'GET', url: `${AUTH_USER}?include=` })
      .then((r) => {
        resolve(r.data?.data?.user)
      })
      .catch((e) => {
        showSmallErrorNotif($t('invalid_token_error_message'))
        reject(e)
      })
  })
}

export default loginAs
